import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ['addRuleBtn', 'rulesContainer', 'newRuleTemplate'];

  connect() {
    super.connect();
    this.addRuleBtnTarget.addEventListener('click', this.addDiscount.bind(this));
  }

  disconnect() {
    super.disconnect();
    this.addRuleBtnTarget.removeEventListener('click', this.addDiscount.bind(this));
  }

  addDiscount() {
    const newRow = this.newRuleTemplateTarget.cloneNode(true);

    newRow.querySelectorAll('input[type=number][disabled]').forEach(el => el.removeAttribute('disabled'));
    newRow.style.display = '';
    newRow.id = '';

    const ruleIndex = this.rulesContainerTarget.querySelectorAll('tr:not([style*="display: none"])').length + 1;
    newRow.innerHTML = newRow.innerHTML.replace(/ORDER_NUMBER/g, ruleIndex);

    const orderNumberInput = newRow.querySelector('input.input[type=text]');
    if (orderNumberInput) orderNumberInput.value = ruleIndex;

    this.rulesContainerTarget.appendChild(newRow);
  }

  removeDiscount(event) {
    event.target.closest('tr').remove();
  }
}
