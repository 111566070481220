import ApplicationController from '../../application_controller';
import dropin from 'braintree-web-drop-in';

export default class extends ApplicationController {
  static targets = [
    'payBtn',
  ];

  connect() {
    super.connect();
    this.checkoutClientToken = this.element.dataset.checkoutClientToken;

    this.initializeDropIn();
  }

  disconnect() {
    super.disconnect();
  }

  initializeDropIn() {
    if (this.checkoutClientToken === '') {
      return;
    }

    const submitButton = this.payBtnTarget;

    dropin.create({
      authorization: this.checkoutClientToken,
      container: '#bt_drop_in'
    }, function (createErr, dropinInstance) {
      if (createErr) {
        console.error('Error creating Drop-in instance:', createErr);
        return;
      }

      submitButton.addEventListener('click', function (event) {
        event.preventDefault();
        console.log('------->Submit button clicked');

        dropinInstance.requestPaymentMethod(function (err, payload) {
          if (err) {
            console.error('Error requesting payment method:', err);
            return;
          }

          const formItem = submitButton.closest('form');
          const formData = new FormData(formItem);
          const formObject = {};
          formData.forEach((value, key) => {
            formObject[key] = value;
          });

          var paymentData = {
            payment_method_nonce: payload.nonce,
            ...formObject
          };

          const nonceInput = document.createElement('input');
          nonceInput.setAttribute('type', 'hidden');
          nonceInput.setAttribute('name', 'payment_method_nonce');
          nonceInput.setAttribute('value', payload.nonce);
          formItem.appendChild(nonceInput);

          formItem.submit();
          submitButton.setAttribute('disabled', true);
        });
      });

      if (dropinInstance.isPaymentMethodRequestable()) {
        // This will be true if you generated the client token
        // with a customer ID and there is a saved payment method
        // available to tokenize with that customer.
        submitButton.removeAttribute('disabled');
      }

      dropinInstance.on('paymentMethodRequestable', function (event) {
        console.log(event.type); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
        console.log(event.paymentMethodIsSelected); // true if a customer has selected a payment method when paymentMethodRequestable fires
        submitButton.removeAttribute('disabled');
      });

      dropinInstance.on('noPaymentMethodRequestable', function () {
        submitButton.setAttribute('disabled', true);
      });
    });
  }
}
