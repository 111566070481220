import ApplicationController from '../../application_controller';
import { hideLoadingSpinner, showLoadingSpinner } from '../../utilities/loading_spinner';
import { handlePromoSuccess, handlePromoError } from '../../utilities/order_summary_promo_application';

export default class extends ApplicationController {
  connect() {
    super.connect();

    document.addEventListener('promo-update-success', this.handlePromoSuccess);
    document.addEventListener('promo-update-error', this.handlePromoError);

    this.handleOrderPreferenceAfterUnpause();
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener('promo-update-success', this.handlePromoSuccess);
    document.removeEventListener('promo-update-error', this.handlePromoError);
  }

  orderMore(event) {
    event.preventDefault();
    showLoadingSpinner();
    this.stimulate('Store::MembersPortal::OrderNow#set_order_now_details').then(hideLoadingSpinner);
  }

  confirmOrderMore(event) {
    event.preventDefault();
    event.currentTarget.style = 'pointer-events: none;';
    event.currentTarget.classList.add('bg-gray-light');
    event.target.textContent = 'Processing...';
    this.stimulate('Store::MembersPortal::OrderNow#set_order_now_details');
  }

  handlePromoSuccess(event) {
    const { result } = event.detail;
    const promoTextField = document.getElementById('promo_code');
    const resultTag = document.getElementById('promo-code-result');
    handlePromoSuccess(result, promoTextField, resultTag);
  }

  handlePromoError(event) {
    const { result } = event.detail;
    const resultTag = document.getElementById('promo-code-result');
    handlePromoError(result, resultTag);
  }

  handleOrderPreferenceAfterUnpause() {
    const orderNowAfterUnpause = document.getElementById('order-now-after-unpause');
    const orderLaterAfterUnpause = document.getElementById('order-later-after-unpause');

    if (orderNowAfterUnpause) {
      orderNowAfterUnpause.remove();
      showLoadingSpinner();
      this.callStimulusAction({
        cb: () => {
          this.stimulate(
            'Store::MembersPortal::OrderNow#set_order_now_details'
          ).then(hideLoadingSpinner);
        },
      });
    }

    if (orderLaterAfterUnpause) {
      const subscriptionId = orderLaterAfterUnpause.dataset.subscriptionId;
      orderLaterAfterUnpause.remove();
      showLoadingSpinner();
      this.callStimulusAction({
        cb: () => {
          this.stimulate(
            'Store::MembersPortal::ChangeNextProcessingDate#set_next_processing_date_details',
            subscriptionId
          ).then(hideLoadingSpinner);
        },
      });
    }
  }

  resumeSubFromOrderSummary(event) {
    event.preventDefault();
    this.callStimulusAction({
      cb: () => {
        document.querySelector('button[data-action="click->modal#closeModal"]').click();
        this.stimulate(
          'Store::MembersPortal::ChangeSubscription#set_change_subscription_details',
          event.currentTarget.dataset.subscriptionId,
          event.currentTarget.dataset.variantId
        );
      },
    });
  }
}
