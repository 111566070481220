import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = [
    'otherReasonContainer',
    'textArea',
    'cancellationReasonsHidden',
    'reasonDropdown',
    'reviewNote',
  ];

  connect() {
    this.initializeSelect2();
  }

  initializeSelect2() {
    const s = $(this.reasonDropdownTarget)
      .select2({
        minimumResultsForSearch: Infinity,
        width: '400px',
      })
      .on('change', this.syncCancellationReason.bind(this));
  }

  syncCancellationReason(event) {
    const selectedValue = this.reasonDropdownTarget.value;

    const isOtherSelected = selectedValue === 'other';
    this.otherReasonContainerTarget.style.display = isOtherSelected
      ? 'block'
      : 'none';

    if (isOtherSelected) {
      this.cancellationReasonsHiddenTarget.value =
        this.textAreaTarget.value || '';
    } else {
      this.cancellationReasonsHiddenTarget.value = selectedValue;
      this.textAreaTarget.value = '';
    }

    this.updateReviewNote();
    this.enableSubmitButton();
  }

  enableSubmitButton() {
    const button = document.querySelector('#deny_request_button');
    button.removeAttribute('disabled');
  }

  updateOtherReason() {
    if (this.otherReasonContainerTarget.style.display === 'block') {
      this.cancellationReasonsHiddenTarget.value = this.textAreaTarget.value;
    }
  }

  updateReviewNote() {
    const prescriptonForm = document.querySelector('#prescription_form');
    const reviewNote = this.reviewNoteTarget;
    reviewNote.value = prescriptonForm.querySelector('.review_note').value;
  }
}
