import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ['ingredientFields', 'container', 'destroyField'];

  addIngredient(event) {
    event.preventDefault();

    const newField = this.createIngredientField();

    this.resetInputFields(newField);
    this.updateFieldPosition(newField);
    this.containerTarget.appendChild(newField);
  }

  removeIngredient(event) {
    event.preventDefault();
    const field = event.currentTarget.closest('.ingredient-fields');
    if (field) this.markFieldForRemoval(field);
  }

  createIngredientField() {
    const newField = this.ingredientFieldsTarget.cloneNode(true);
    newField.classList.remove('hidden');
    return newField;
  }

  resetInputFields(field) {
    field.querySelectorAll('input').forEach((input) => {
      input.value = '';
      input.removeAttribute('value');
      this.assignUniqueNameAndId(input);
    });
  }

  assignUniqueNameAndId(input) {
    const nameMatch = input.name.match(/\[\d+\]/);
    if (nameMatch) {
      const uniqueIndex = new Date().getTime();
      input.name = input.name.replace(nameMatch[0], `[${uniqueIndex}]`);
      input.id = input.id.replace(nameMatch[0], `[${uniqueIndex}]`);
    }
  }

  updateFieldPosition(field) {
    const nextPosition = this.containerTarget.querySelectorAll(
      '.ingredient-fields:not(.hidden)',
    ).length;
    const positionInput = field.querySelector('[name$="[position]"]');
    if (positionInput) positionInput.value = nextPosition;
  }

  markFieldForRemoval(field) {
    const destroyField = field.querySelector(
      "[data-target='admin--forms--medication.destroyField']",
    );
    if (destroyField) {
      destroyField.value = '1';
      field.style.display = 'none';
    }
  }
}
