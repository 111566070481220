import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ['template'];

  connect() {
    super.connect();
  }

  addField() {
    const container = document.getElementById('image-descriptions-fields');
    const template = this.templateTarget.cloneNode(true); // Clone the hidden template
    template.classList.remove('hidden'); // Remove hidden class
    template.removeAttribute('data-image-descriptions-target'); // Remove the target attribute
    container.appendChild(template);
  }

  removeField(event) {
    event.currentTarget.parentElement.remove();
  }
}
