import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['consentAsked'];

  connect() {
    super.connect();
    document.addEventListener('BLOTOUT_CONSENT_GRANTED', this.onConsentGranted.bind(this));
    document.addEventListener('BLOTOUT_CONSENT_DENIED', this.onConsentDenied.bind(this));
    this.checkBlotoutConsent();
  }

  onConsentGranted(event) {
    event.preventDefault();
    window.edgetag('consent', { all: true });
    window.edgetag('user', 'email', event.detail.email);
    window.edgetag('tag', 'UserRegistered');
  }

  onConsentDenied(event) {
    event.preventDefault();
    window.edgetag('consent', { all: false });
    window.edgetag('tag', 'UserRegistered');
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener('BLOTOUT_CONSENT_GRANTED', this.onConsentGranted.bind(this));
    document.removeEventListener('BLOTOUT_CONSENT_DENIED', this.onConsentDenied.bind(this));
  }

  checkBlotoutConsent() {
    if (this.consentAskedTarget.value === 'false') {
      // launch the Blotout consent modal
      this.callStimulusAction({
        cb: () => {
          this.stimulate(
            'UserDemographics::BlotoutConsent#render_consent_form'
          );
        },
      });
    }
  }
}
